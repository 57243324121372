import React, { useState, useEffect } from 'react';
import Timer from './Timer';
import { logoutUser } from "../src/features/user/userSlice";
import { clearExam } from "./features/exam/examSlice";
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchExamById,
  setSelectedAnswerForQuestion,
} from './features/exam/examSlice.js';
import QuestionBubble from './QuestionBubble.jsx';
import { useNavigate } from 'react-router';
import axios from 'axios';
import { API_URL } from './constants.js';

export default function Exam() {
  const {
    ExamPassingGradeThreshhold,
    ExamDateBarrier,
    ExamTimeAllowed,
    loading,
    error,
  } = useSelector(state => state.exam);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const examtest = useSelector(state => state.exam);
  const questions = useSelector(state => state.exam.examquestions);
  const examID = useSelector(state => state.user.exams.ExamID);
  const courseIDfromUser = useSelector(state => state.user.exams.CourseID);
  const Cert = useSelector(state => state.exam.ExamName);
  const userID = useSelector(state => state.user.user.CertUserID);
  const FirstName = useSelector(state => state.user.user.FirstName);
  const LastName = useSelector(state => state.user.user.LastName);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [skipped, setSkipped] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [resultMessage, setResultMessage] = useState('');
  const sections = useSelector(state => state.exam.sections);

  useEffect(() => {
    if (!loading && !error && questions.length === 0 && courseIDfromUser) {
      dispatch(fetchExamById(examID));
    } else if (
      !loading &&
      !error &&
      questions.length === 0 &&
      !courseIDfromUser
    ) {
      alert('An error occurred. Please try again.');
      navigate('/examentry');
    }
  }, [
    sections,
    questions.length,
    courseIDfromUser,
    loading,
    error,
    dispatch,
    examID,
    examtest,
    navigate,
  ]);

  window.onbeforeunload = function (e) {
    e.preventDefault();
    return alert('Warning! Refreshing the page will restart the entire exam');
  };

  const handleBubbleClick = index => {
    const newSkipped = questions.filter(
      (q, i) =>
        (q.selectedAnswer == null && i <= index) ||
        skipped.map(q => q.ExamQuestionID).includes(q.ExamQuestionID)
    );

    setSkipped(newSkipped);
    setCurrentQuestionIndex(index);
  };

  const handleSubmit = () => {
    setShowScore(true);
    generateMessage(calculateScore());
  };

  const handleAnswerOptionClick = Answer => {
    // When an answerOption is selected, dispatch an update to the store
    dispatch(
      setSelectedAnswerForQuestion({
        ExamQuestionID: questions[currentQuestionIndex].ExamQuestionID,
        Answer,
      })
    );

    //Get all of the skipped questions other than this one
    const skippedOtherThanHere = skipped.filter(
      question =>
        question.ExamQuestionID !==
        questions[currentQuestionIndex].ExamQuestionID
    );

    //Update the skipped state array
    setSkipped(skippedOtherThanHere);

    const nextQuestion = questions[currentQuestionIndex + 1];

    if (nextQuestion) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else if (skippedOtherThanHere.length > 0) {
      //Otherwise, go back to the first skipped question
      setCurrentQuestionIndex(
        questions
          .map(q => q.ExamQuestionID)
          .indexOf(skippedOtherThanHere.map(sq => sq.ExamQuestionID)[0])
      );
    }
  };

  const handleBackClick = () => {
    const nextQuestion = questions[currentQuestionIndex - 1];
    if (questions.indexOf(nextQuestion) < questions.length) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (
      questions[currentQuestionIndex].selectedAnswer == null &&
      !skipped
        .map(q => q.ExamQuestionID)
        .includes(questions[currentQuestionIndex].ExamQuestionID)
    ) {
      setSkipped([...skipped, questions[currentQuestionIndex]]);
    }

    if (currentQuestionIndex + 1 < questions.length) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  //show the header for each question
  const showHeaderSection = () => {
    // Get the section for the currentQuestion - Now this only happens here, and we don't need to set it anywhere else
    try {
      const currentSection = sections.filter(
        section =>
          section.SectionID === questions[currentQuestionIndex].SectionID
      )[0];

      if (currentSection) {
        return currentSection.Name + ' (' + currentSection.Score + ' pts) ';
      } else {
        return '';
      }
    } catch (e) {}
  };

  //Calculate based on section points and perform calculation on percentage of points out of possible points
  const calculateScore = () => {
    //Get the points available for each question based on the SectionID, and then reduce the total down by summing
    const totalPossibleScore = questions
      .map(
        question =>
          sections.filter(
            section => section.SectionID === question.SectionID
          )[0].Score
      )
      .reduce((acc, val) => acc + val);

    const pointsPerQuestion = questions.map(
      question =>
        question.selectedAnswer != null
          ? question.selectedAnswer.Answer ===
            question.examanswers
              .filter(answer => answer.IsCorrect) //Find only the correct answer
              .map(ans => ans.Answer)[0] //Get the answerText for each correct answer
            ? sections.filter(
                section => section.SectionID === question.SectionID
              )[0].Score // Get the score for this question based on the SectionID
            : 0
          : 0 // If no answer was selected, automatic 0
    );

    return Math.round(
      (pointsPerQuestion.reduce((acc, val) => acc + val) / totalPossibleScore) *
        100
    );
  };

  //generate message from results of test
  const generateMessage = score => {
    if (!isSubmitted) {
      sendResults(score);
      //check against the dbfield for exampassinggradethreshold value instead of .8
      if (score >= ExamPassingGradeThreshhold) {
        setResultMessage(
          `Congrats! You have passed this exam with a score of ${score}%`
        );
      } else {
        setResultMessage(
          `You scored: ${score}% \nSorry, but you have not scored the minimum amount of ${ExamPassingGradeThreshhold}% needed to receive a certificate.  \nYou can try again in ${ExamDateBarrier} days.`
        );
      }
    }else{
      //clear exam and logout user
      dispatch(clearExam());
      dispatch(logoutUser());
    }
    
  };

  const sendResults = score => {
    let today = new Date();
    let attempt = 1;
    let certuserexam = {
      UserID: userID,
      ExamID: examID,
      ExamAttempt: 1,
      ExamGrade: score,
      ExamDate: today,
    };
    axios
      .post(`${API_URL}/certuserexam`, { certuserexam })
      .then(response => {
        console.log('Exam Score Response: ', response);
        //navigate to cert page
        setIsSubmitted(true);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const addCertRecord = () => {
    let CertDate = new Date();
    axios
      .post(`${API_URL}/addusercert`, { FirstName, LastName, CertDate, Cert })
      .then(response => {
        console.log('Cert Record Response:', response.data);
        navigate(`/cert-generation/${courseIDfromUser}`, { state: response.data });
      });
  };

  const submitOrAddCert = () => {
    if (isSubmitted) {
      addCertRecord();
    } else {
      handleSubmit();
    }
  };

  return (
    <>
      <div className='examPageWrapper'>
        <div className='timer'>
          <Timer
            examtimeallowed={ExamTimeAllowed}
            timeUp={() => setShowScore(true)}
            isSubmitted={isSubmitted}
          />
        </div>

        {!isSubmitted && (
          <div className='header'>
            <h1>{showHeaderSection()}</h1>
          </div>
        )}

        <div className='app'>
          {showScore ? (
            <div className='container'>
              <div className='score-section'>
                {resultMessage}
                {/* {generateMessage(calculateScore())} */}
              </div>
            </div>
          ) : (
            <>
              <div className='questionContainer'>
                <div className='question-count'>
                  <span>Question {currentQuestionIndex + 1}</span>/
                  {questions.length}
                </div>
                <div className='question-text'>
                  {questions[currentQuestionIndex]?.Question}
                </div>
                <div className='answerSection'>
                  {questions[currentQuestionIndex]?.examanswers?.map(
                    answerOption => (
                      <button
                        className='answerOption'
                        key={answerOption.Answer}
                        onClick={() =>
                          handleAnswerOptionClick(answerOption.Answer)
                        }
                        //   If this option has been previously selected by the test-taker, highlight it.
                        style={
                          questions[currentQuestionIndex].selectedAnswer
                            ?.Answer === answerOption.Answer
                            ? { backgroundColor: '#555e7d' }
                            : { '': '' }
                        }
                      >
                        {answerOption.Answer}
                      </button>
                    )
                  )}
                </div>
                <p></p>
                <div className='navigationButtonsContainer'>
                  <button
                    disabled={currentQuestionIndex === 0}
                    className='btnBack'
                    onClick={() => handleBackClick()}
                  >
                    Back
                  </button>

                  <button
                    disabled={currentQuestionIndex === questions.length - 1}
                    className='btnNext'
                    onClick={() => handleNextClick()}
                  >
                    Next
                  </button>
                </div>
              </div>
            </>
          )}
        </div>

        <div className='questionBubbleContainer'>
          <div
            style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
          >
            {/* Get an array of numbers [0, 1, 2, ... numQuestions / 10 + 1] */}
            {Array.from(
              Array(Math.floor(questions.length / 10) + 1).keys()
            ).map(index => (
              <div className='questionBubbleRow' key={index}>
                {/* Each row is a slice of the questions: 1-10, 11-20, ...*/}
                {questions
                  .slice(index * 10, (index + 1) * 10)
                  .map((question, i) => (
                    <div className='questionBubbleColumn' key={i + index * 10}>
                      <QuestionBubble
                        disabled={showScore}
                        key={questions.indexOf(question)}
                        onClick={() => handleBubbleClick(i + index * 10)}
                        skipped={skipped
                          .map(q => q.ExamQuestionID)
                          .includes(question.ExamQuestionID)}
                        current={
                          question.ExamQuestionID ===
                          questions[currentQuestionIndex].ExamQuestionID
                        }
                        answered={question.selectedAnswer != null}
                        questionNumber={questions.indexOf(question) + 1}
                      />
                    </div>
                  ))}
              </div>
            ))}
          </div>
        </div>
        {/* Note that a == null is true if a is null and also if a is undefined. (As opposed to a === null.) */}
        <div className='submitContainer'>
          {questions.filter(q => q.selectedAnswer == null).length > 0 &&
          !isSubmitted ? (
            <button
              className='btnSubmitPending'
              onClick={() => handleSubmit()}
              disabled={true}
            >
              Submit <br /> All Questions Must be Answered Prior to Submission
            </button>
          ) : (
            <button
              className='btnSubmitReady'
              //TODO: onClick
              onClick={submitOrAddCert}
              disabled={false}
            >
              {isSubmitted ? 'Proceed to Certification' : 'Submit'}
            </button>
          )}
        </div>
      </div>
    </>
  );
}
