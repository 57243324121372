import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router";
import { TextField, Button, FormGroup, InputLabel, Grid } from "@mui/material"
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { DatePicker } from "@mui/x-date-pickers"
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import axios from "axios"
import {API_URL} from "../constants";

export default function CertForm() {
    const navigate = useNavigate();
    const [FirstName, setFirstName] = useState("");
    const [LastName, setLastName] = useState("");
    const [CertDate, setCertDate] = useState("");
    const [Cert, setCert] = useState("");
    const [CertID, setCertID]= useState(0);
    const [Courses, setCourses] = useState([]);
    const [courseid, setcourseid] = useState(0);
    const [alignment, setAlignment] = React.useState('left');
    
    //update post to send userid instead of fn,ln
    function submitValues(){
        console.log('Values before submit: ',FirstName, LastName, CertDate, Cert);
        axios.post(`${API_URL}/addusercert`, { FirstName, LastName, CertDate, Cert}).then((response) => {
            console.log('Response.data obj:' , response.data); 
            //return object and send to certgeneration page
            //navigate('/cert-generation', {  state: {'FirstName':FirstName, 'LastName':LastName, 'Date':Date, 'Cert':Cert, "CertID": "05045", 'Code': '097379d1-40f1-40a2-9770-d24c9e5aea9f'}});
            navigate(`/cert-generation/${courseid}`, {state: response.data });
        })
    }

    const handleSelection = (event, newSelection) => {
        console.log('Selected Course: ' + newSelection);
        setcourseid(newSelection);
      };

    useEffect(()=> {
        axios.get(`${API_URL}/getcourses`).then(res=> {
            console.log('Course List: ' + res.data);
            setCourses(res.data)
        }).catch(e=> {
            console.log(e);
        });
    },[])

    return(
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="container" style={{paddingTop:75}}>
            <h1 style={{color:"black", paddingLeft:15}}>Certificate Generation Form</h1>
            <ToggleButtonGroup
                value={alignment}
                exclusive
                onChange={handleSelection}
                aria-label="Course Selection"
            >
                <ToggleButton value='0' aria-label='base'>
                   Base
                </ToggleButton>
                {
                    Courses.map(course => {
                    //remove the test course
                    if(course.CourseID !== 8){
                    return <ToggleButton value={course.CourseID} aria-label={course.CourseName}>
                        {course.CourseName}
                    </ToggleButton>
                    }
                })
                }
    </ToggleButtonGroup>
            <form onSubmit={submitValues}>
                <Grid container spacing="2" paddingX={2}>
                    <Grid item lg={12} md={10} sm={10} paddingBottom={2}>
                            <FormGroup>
                                <InputLabel>First Name:</InputLabel>
                                <TextField name="FirstName" placeholder="Enter First Name" onChange={e => setFirstName(e.target.value)}/>
                            </FormGroup>
                    </Grid>
                    <Grid item lg={12} md={10} sm={10} paddingBottom={2}>
                            <FormGroup>
                                <InputLabel>Last Name:</InputLabel>
                                <TextField name="LastName" placeholder="Enter Last Name" onChange={e => setLastName(e.target.value)}/>
                            </FormGroup>
                    </Grid>
                    <Grid item lg={12} md={10} sm={10} paddingBottom={2}>
                            <FormGroup>
                                <InputLabel>Certification:</InputLabel>
                                <TextField name="Certification" placeholder="Enter Certification Name" onChange={e => setCert(e.target.value)}/>
                                <small>This is the name that will show up on the certificate</small>
                            </FormGroup>
                    </Grid>
                    <Grid item lg={12} md={10} sm={10} paddingBottom={2}>
                            <FormGroup>
                                <InputLabel>Date:</InputLabel>
                                <DatePicker format="MM/DD/YYYY" name="CertDate" onChange={e => setCertDate(e)}></DatePicker>
                            </FormGroup>
                    </Grid>
                    <Grid item lg={12} md={10} sm={10} paddingBottom={2}>
                            <Button color="primary" variant="outlined" onClick={submitValues}>Generate Certificate</Button>
                    </Grid>
                </Grid>
            </form>
            </div>
        </LocalizationProvider>
    )

}