import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Outlet,
} from "react-router-dom";
import CertForm from "./Forms/CertForm";
import Exam from "./Exam";
//import FrontPage from "./Forms/FrontPage";
import CertGeneration from "./CertGeneration";
import ExamEntry from "./Forms/ExamEntry";
import Login from "./Forms/Login";
import ExamsList from "./ExamsList";
import SectionList from "./SectionList";
import SectionEntry from "./Forms/Exams/SectionCreation";
import QuestionsList from "./QuestionsList";
import ExamCreation from "./Forms/Exams/ExamCreation";
import ExamQuestionEntry from "./Forms/Exams/ExamQuestionEntry";
import NotFound from "./NotFound";
import FrontPage from "./Forms/FrontPage"
import ProtectedRoute from "./ProtectedRoute";
import Menu from "./Menu";
export default function App() {
  
  return (
    <Router>
      <Menu />
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<FrontPage/>} />
        <Route path="/cert-form" element={<CertForm />} />
        <Route path="/cert-generation" element={<CertGeneration />} />
        {/* <Route path="/login" element={<Login />} /> */}
        {/* <Route element={<RequireAuth/>}> */}
        <Route path="/exam" element={<Exam />} />
        <Route path="/examentry" element={<ExamEntry />} />
        {/*  </Route>
        <Route element={<RequireAdminAuth/>}> */}
        <Route path="/section-list" element={<SectionList />} />
        <Route path="/section-creation" element={<SectionEntry />} />
        <Route path="/cert-form" element={<CertForm />} />
        <Route path="/cert-generation" element={<CertGeneration />} />
        <Route path="/cert-generation/:courseid" element={<CertGeneration />} />
        <Route path="/exam-list" element={<ExamsList />} />
        <Route path="/exam-creation" element={<ExamCreation />} />
        <Route path="/exam-question-list" element={<QuestionsList />} />
        <Route path="/exam-question-creation" element={<ExamQuestionEntry />} />
        {/*  </Route> */}
      </Routes>
    </Router>
  );
}
